import { FormField } from '../../../../deps/@bshgroup/component-form-field';
import { Input, InputContent, InputContentInput, InputContentLabel } from '../../../../deps/@bshgroup/component-input';
import { useTokens } from '../../../../deps/@bshgroup/component-themes';
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';
import countryPhoneCodes from '../../../global/country-phone-codes.json';
import { useTranslation } from '../../../contexts/TranslationContext/TranslationContext';
import { useForm } from '../../context/FormContext/FormContext';
import { Hint } from '../Hint/Hint';
import { Button } from '../Button/Button';

// Currently only displaying and selecting the first form autocomplete option is supported

export type InputType = 'default' | 'phone' | 'number';

export interface FormInputProps {
  /**
   * The id of the input, needs to be the name of the property of the form
   */
  id: string;
  /**
   * The type of the input
   */
  type?: InputType;
  /**
   * The label of the input
   */
  label?: string;
  /**
   * The placeholder of the input
   */
  placeholder?: string;
  /**
   * Whether the input value is required
   */
  required?: boolean;
  /**
   * Messages to be displayed when the input validation shows an error
   */
  messages?: { [key: string]: string };
  /**
   * Whether the input is disabled
   */
  disabled?: boolean;
  /**
   * The autocomplete attribute of the input
   */
  autocomplete?: string;
  /**
   * Whether the form should be submitted when the user presses enter inside the field
   */
  submitOnEnter?: boolean;
  /**
   * Whether the clear button should be shown
   */
  clearable?: boolean;
}

/**
 * An input component to be used inside a `FormContext`
 */
export function FormInput({
  id,
  type,
  disabled,
  label,
  required,
  placeholder,
  messages = {},
  autocomplete,
  submitOnEnter = false,
  clearable = false,
  ...props
}: FormInputProps & Omit<Partial<React.HTMLAttributes<HTMLLabelElement>>, keyof FormInputProps>) {
  const { t } = useTranslation();
  const tokens = useTokens();
  const { inputHandlers, setValue, touched, errors, activeInput, autocompleteOptions } = useForm();
  const [showAutocomplete, setShowAutocomplete] = React.useState(true);

  let indicatorLeadingId = '';
  if (type === 'phone') {
    indicatorLeadingId = `${id}.countryCode`;
    id = `${id}.phoneNumber`;
  }

  // React uses focusout events for the onBlur event, which are not triggered by autofill, so we have to listen for real onblur events manually
  const inputRef = useRef<any>();
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('blur', handleOnBlur);
    }
  }, [inputRef]);

  const setAutocompleteValue = () => {
    if (autocompleteOptions[id]?.[0]) {
      if (typeof autocompleteOptions[id]?.[0].value === 'string') {
        setValue(id, autocompleteOptions[id][0].value);
      } else {
        Object.entries(autocompleteOptions[id][0].value).forEach(([key, value]) => {
          setValue(key, value);
        });
      }
      setShowAutocomplete(false);
      inputRef.current?.blur();
    }
  };

  const handleOnFocus = () => {
    inputHandlers(id).onFocus();
    setShowAutocomplete(true);
  };

  const handleOnKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      setShowAutocomplete(false);
    } else if (showAutocomplete && (e.key === 'Tab' || e.key === 'Enter') && autocomplete?.[0]) {
      setAutocompleteValue();
    } else if (submitOnEnter && e.key === 'Enter') {
      inputHandlers(id).onSubmit();
    }
  };

  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    inputHandlers(id).onBlur();

    // detect if country code was entered in main field (by autofill) and fix by removing it and setting the selection correctly
    const value = e.target.value;
    if (type === 'phone' && value.trim().startsWith('+')) {
      const matchingCountryCodes = countryPhoneCodes.filter(e =>
        value.replace(/\D/g, '').startsWith(e.call.replace(/\D/g, '')),
      );
      if (matchingCountryCodes.length) {
        matchingCountryCodes.sort((a, b) => b.call.length - a.call.length);
        setValue(indicatorLeadingId, matchingCountryCodes[0].country);
        setValue(
          id,
          value
            .replace(
              new RegExp(`\D*[+]\D*${matchingCountryCodes[0].call.replace(/\D/g, '').split('').join('D*')}\D*`),
              '',
            )
            .trim(),
        );
      }
    }
  };

  return (
    <FormField
      disabled={disabled}
      css={{ gap: 0, minWidth: 0 }}
      control={config => (
        <Input
          {...config}
          size="sm"
          variant="default"
          indicatorLeading={() =>
            type === 'phone' ? (
              <select
                {...inputHandlers(indicatorLeadingId)}
                id={indicatorLeadingId}
                css={{ border: 'none', background: 'none' }}
                aria-label={t('Components.InputField.CallPrefixLabel')}
              >
                {countryPhoneCodes.map(value => (
                  <option key={value.country} value={value.country}>
                    +{value.call}
                  </option>
                ))}
              </select>
            ) : (
              <></>
            )
          }
          content={config => (
            <>
              <InputContent
                {...config}
                css={{
                  div: { width: 'calc(100% - 1rem)', overflow: 'hidden' },
                  paddingInlineEnd: clearable ? '2rem' : '0.5rem',
                }}
                label={config => (
                  <InputContentLabel
                    {...config}
                    as="label"
                    htmlFor={id}
                    css={{ display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  >
                    {label}
                    {required && ' *'}
                  </InputContentLabel>
                )}
                input={config => (
                  <InputContentInput
                    {...config}
                    {...inputHandlers(id)}
                    onFocus={handleOnFocus}
                    onKeyUp={handleOnKeyUp}
                    onBlur={handleOnBlur}
                    ref={inputRef}
                    id={id}
                    type={type === 'number' ? 'number' : 'text'}
                    required={required}
                    placeholder={placeholder}
                    autoComplete={autocomplete}
                    aria-describedby={`${id}-error`}
                    aria-label={label ? undefined : placeholder}
                    css={{ '&::placeholder': { opacity: label ? 'auto' : 0.4 } }}
                  />
                )}
              />
              {clearable && (
                <Button
                  variant="icononly"
                  icon="close"
                  onMouseDown={e => {
                    if (document.activeElement === inputRef.current) {
                      setTimeout(() => inputRef.current?.focus(), 0);
                    }
                    inputHandlers(id).onChange(e as any, '');
                  }}
                  css={{
                    position: 'absolute',
                    right: '0.5rem',
                    width: 'auto !important',
                    height: '100%',
                    display: 'grid',
                    placeContent: 'center',
                  }}
                />
              )}
            </>
          )}
        />
      )}
      message={() =>
        (showAutocomplete && activeInput === id && autocompleteOptions[id]?.[0] && (
          <div css={{ backgroundColor: tokens.colors['secondary-base-200'], padding: '0.5rem 0' }}>
            <div
              css={{ backgroundColor: tokens.colors['primary-base-100'], padding: '0.5rem 1rem', cursor: 'pointer' }}
              // onClick does not work here for some reason
              onMouseDown={setAutocompleteValue}
            >
              {autocompleteOptions[id]?.[0].displayName}
            </div>
          </div>
        )) ||
        (touched[id] && errors[id] && (
          <Hint id={`${id}-error`} level="error" description={messages?.[errors[id]]} aria-live="polite" />
        )) || <></>
      }
      {...props}
    />
  );
}
